<template>
  <div>
    <div class="page">
      <div class="g-flex-row">
        <img src="@i/yj.png" class="icon" />
        <span class="ft30 cl-000">千川管家</span>
      </div>
      <span class="yj-title">流量管理 降低风险</span>
      <span class="yj-tip">自定阈值，即时监控，风险预警</span>
      <!-- <router-link to="/index">
        <span class="yj-button mt20">立即使用</span>
      </router-link> -->
      <div class="yj-button disabled mt20">正在维护</div>
      <div class="mt10">客服电话：18767141341</div>
      <div class="img-bg">
        <div class="left-img"></div>
        <div class="middle-img"></div>
        <div class="right-img"></div>
      </div>
      <div class="g-introduce-container mt85">
        <div class="g-introduce-content">
          <span class="cl-click">设定风险值，自动关联计划</span>
          <p class="g-introduce-content-title mt20">阈值</p>
          <p class="g-introduce-content-text mt30">
            自定义检测阈值，多个维度监控
          </p>
          <p class="g-introduce-content-text">降低投放过程中不必要的损失</p>
          <span class="g-introduce-content-content mt50"
            >· 3个检测维度设定</span
          >
          <span class="g-introduce-content-content "
            >· 计划触达维度阈值自动关闭并报警</span
          >
          <span class="g-introduce-content-content "
            >· 预估计划正常进行的结束时间</span
          >
        </div>
        <img
          src="../../assets/images/product/case1.png"
          class="ml20"
          style="width:604px;height:130px"
        />
      </div>
      <div
        class="g-introduce-container mt85"
        style="flex-direction: row-reverse;"
      >
        <div class="g-introduce-content ml20">
          <span class="cl-click">预警提醒，自动关闭</span>
          <p class="g-introduce-content-title mt20">提醒</p>
          <p class="g-introduce-content-text mt30">
            在千川数据页可直接查看数据漂浮层，提示信息
          </p>
          <p class="g-introduce-content-text">关闭原因等会特殊表明</p>
          <span class="g-introduce-content-content mt30"
            >· 可在投放计划数据页面直接查看</span
          >
          <span class="g-introduce-content-content ">· 设置阈值即时生效</span>
          <span class="g-introduce-content-content "
            >· 数据安全由128位证书加密，安全保证</span
          >
        </div>
        <img
          src="../../assets/images/product/case2.png"
          style="width:604px;height:130px"
        />
      </div>
    </div>
    <div class="bottom-container">
      <span class="bottom-title">现在使用抖脉 开始创意投放</span>
      <router-link to="/index">
        <span class="bottom-button">立即使用</span>
      </router-link>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {}
  },
  created () {},
  methods: {
    // ------------------ 点击方法
    // ------------------ 请求方法
    // ------------------ 其它
  }
}
</script>
<style lang="less" scoped>
.page {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 48px;
  .yj-title {
    font-size: 46px;
    font-weight: bold;
    color: #000000;
    line-height: 66px;
    margin-top: 27px;
  }
  .yj-tip {
    font-size: 18px;
    font-weight: 400;
    color: #86868b;
    margin-top: 20px;
  }
  .yj-button {
    width: 180px;
    height: 50px;
    background: #2878ff;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 22px;
    .pointer;
    &.disabled {
      background-color: #757575;
      cursor: not-allowed;
    }
  }
  .img-bg {
    height: 422px;
    width: 1155px;
    position: relative;
    margin-top: 44px;
    .left-img {
      width: 531px;
      height: 387px;
      border-radius: 31px;
      background: url('../../assets/images/case1.png');
      position: absolute;
      bottom: 0;
      left: 0;
      border: 3px #333 solid;
    }
    .middle-img {
      width: 644px;
      height: 422px;
      border-radius: 31px;
      background: url('../../assets/images/case1.png');
      position: absolute;
      bottom: 0;
      left: 250px;
      border: 3px #333 solid;
      z-index: 2;
    }
    .right-img {
      width: 531px;
      height: 387px;
      border-radius: 31px;
      background: url('../../assets/images/case1.png');
      position: absolute;
      bottom: 0;
      border: 3px #333 solid;
      right: 0;
    }
  }
}
.bottom-container {
  width: 100%;
  height: 340px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url('../../assets/images/discover/bottom.png');
  margin-top: 96px;
  .bottom-title {
    font-size: 40px;
    font-weight: 800;
    color: #ffffff;
    margin-top: 126px;
  }
  .bottom-button {
    width: 254px;
    height: 59px;
    background: #ff2626;
    border-radius: 30px;
    color: #fff;
    font-size: 20px;
    margin-top: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
}
.icon {
  height: 32px;
  width: 32px;
  margin-right: 10px;
}
</style>
